import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/footer";
import PrivacyPolicy from "../components/policy/PrivacyPolicy";

function App() {
  return (
    <div>
      <Navbar />
      <PrivacyPolicy />
      <Footer />
    </div>
  );
}

export default App;
