import React from "react";
import "../../index.css";
import Plans from "./Plans.js";
import Depoiments from "./Depoiments.js";
import TestimonialCarousel from "../common/TestimonialCarousel.js";
import BottomBanner from "./BottomBanner.js";
import HeroOne from "./HeroOne";
import HeroTwo from "./HeroTwo";

const Hero = () => {
  return (
    <div>
      <HeroOne />
      <HeroTwo />
      <Plans />
      <Depoiments />
      <TestimonialCarousel />
      <BottomBanner />
    </div>
  );
};

export default Hero;
