import React from "react";
import "../../index.css";

const PlansSectionOne = () => {
  return (
    <section className="w-full bg-cover h-[236px]  bg-plans-background content-end pb-6">
      <h1 className="text-center justify-start text-sky-900 text-xs font-bold font-['Raleway'] pb-3">
        O plano que vai mudar a rotina do seu consultório está aqui!{" "}
      </h1>
      <h2 class="text-center justify-start text-texto-1 text-[8.50px] font-normal font-['Fustat']">
        Veja todas as funcionalidades que cada pacote pode oferecer.
      </h2>
    </section>
  );
};

export default PlansSectionOne;
