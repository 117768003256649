import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import "@material/web/textfield/outlined-text-field.js";
import sendButton from "../../assets/images/send-button.png";

function ContactForm() {
  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    mensagem: "",
  });

  // Função para lidar com mudanças nos campos do formulário
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Função para enviar o formulário
  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceID = "service_5344uzl"; // Substitua pelo seu ID do EmailJS
    const templateID = "template_r2uv56a"; // Substitua pelo seu ID do template
    const publicKey = "NQjhxQEjiTq-N0jds"; // Substitua pelo seu Public Key do EmailJS

    const templateParams = {
      from_name: formData.nome,
      from_email: formData.email,
      message: formData.mensagem,
    };

    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then(() => {
        toast.success(
          <div className="flex flex-col items-center justify-center">
            <p className="text-sky-900 text-sm font-semibold font-raleway">
              Mensagem enviada com sucesso!
            </p>
            <p className="text-black text-xs font-normal font-raleway pt-4">
              Sua mensagem está sendo processada, em breve retornaremos.
            </p>
          </div>,

          {
            icon: false,
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
          }
        );
        setFormData({ nome: "", email: "", mensagem: "" });
      })
      .catch((err) => {
        toast.error("Erro ao enviar mensagem. Tente novamente.", {
          position: "top-right",
          autoClose: 3000,
        });
        console.error("Erro:", err);
      });
  };

  return (
    <div className="mt-5 relative flex justify-center items-center">
      <ToastContainer
        position="top-center"
        toastClassName={() =>
          "flex flex-col w-[430px] h-36 bg-neutral-100 rounded-[20px] justify-center items-center"
        }
      />{" "}
      {/* Necessário para exibir as notificações */}
      <form onSubmit={handleSubmit} className="flex flex-col pb-10">
        <div className="pb-6 w-72 sm:w-96 sm:pt-10 sm:pb-8">
          <label
            htmlFor="nome"
            className="absolute -mt-2 ml-3 text-xs text-[#0082BA] bg-white px-2"
          >
            Nome*
          </label>
          <input
            type="text"
            id="nome"
            name="nome"
            className="border border-[#0082BA] rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.nome}
            onChange={handleChange}
          />
        </div>
        <div className="pb-6 sm:pb-8">
          <label
            htmlFor="email"
            className="absolute -mt-2 ml-3 text-xs text-[#0082BA] bg-white px-2"
          >
            E-mail*
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="border border-[#0082BA] rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label
            htmlFor="mensagem"
            className="absolute -mt-2 ml-3 text-xs text-[#0082BA] bg-white px-2"
          >
            Mensagem*
          </label>
          <textarea
            id="mensagem"
            name="mensagem"
            className="border border-[#0082BA] rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 h-40"
            value={formData.mensagem}
            onChange={handleChange}
          />
        </div>
        <button type="submit">
          <img
            src={sendButton}
            alt="Enviar"
            className="relative w-28 lg:w-36 pt-6 cursor-pointer justify-self-end"
          />
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
