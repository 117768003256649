import React from "react";
import "../../index.css";
import SmokeLine from "../../assets/images/smoke-line.png";

const Depoiments = () => {
  return (
    <div className="relative">
      <div className="bg-[url('assets/images/depoiments-background.png')] bg-cover bg-no-repeat bg-center w-full h-96 pt-8">
        {/* Imagem sobre o background */}
        <img
          src={SmokeLine}
          alt="Smoke Line Up"
          className="absolute w-full -top-10 h-20"
        />{" "}
        <img
          src={SmokeLine}
          alt="Smoke Line Down"
          className="absolute w-full -bottom-10 h-20"
        />
        <h3 className="text-sm sm:text-lg font-medium font-['Raleway'] text-[#003e60] mt-10 ml-10 sm:px-[10%] lg:px-[13%] xl:px-[20%]">
          O que nossos clientes falam sobre o PsiControl?
        </h3>
        <p className="text-[#232323] text-xs sm:text-base font-light font-['Fustat'] leading-relaxed ml-10 sm:px-[10%] lg:px-[13%] xl:px-[20%]">
          Veja os depoimentos de quem já usa o sistema!
        </p>
      </div>
    </div>
  );
};

export default Depoiments;
