import React from "react";
import "../../index.css";
import iconCalendar from "../../assets/icons/icon-calendar.png";
import iconCard from "../../assets/icons/icon-card.png";
import iconRelatory from "../../assets/icons/icon-relatory.png";
import iconSecurity from "../../assets/icons/icon-security.png";

const WhyCard = () => {
  return (
    <div className="flex flex-col justify-center gap-2 sm:gap-3 w-[223px] sm:w-80 h-[152px] sm:h-64 rounded-[10px] border border-[#0082ba] mt-4 ">
      <div className="flex items-center">
        <img
          src={iconCalendar}
          alt="Calendário"
          className="w-4 h-[18px] sm:w-8 sm:h-[36px] ml-[17px]"
        />
        <div className="w-[166px] sm:w-60 ml-2">
          <p class="text-black text-[8px] leading-3 sm:text-xs font-light font-['Fustat']">
            <span className="font-semibold">
              Sincronizado com o Google Agenda:{" "}
            </span>
            dados do google agenda automaticamente no sistema.
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <img
          src={iconCard}
          alt="Calendário"
          className="w-4 h-[18px] sm:w-8 sm:h-[36px] ml-[17px]"
        />
        <div className="w-[166px] sm:w-60 ml-2">
          <p class="text-black text-[8px] leading-3 sm:text-xs font-light font-['Fustat']">
            <span className="font-semibold">Cobranças automatizadas: </span>
            envie cobranças diretamente do sistema e economize tempo.
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <img
          src={iconRelatory}
          alt="Calendário"
          className="w-4 h-[18px] sm:w-8 sm:h-[36px] ml-[17px]"
        />
        <div className="w-[166px] sm:w-60 ml-2 mt-2">
          <p class="text-black text-[8px] leading-3 sm:text-xs font-light font-['Fustat']">
            <span className="font-semibold">Relatórios Detalhados: </span>
            accompanhe seus gastos e recebimentos com relatórios mensais.
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <img
          src={iconSecurity}
          alt="Calendário"
          className="w-4 h-[18px] sm:w-8 sm:h-[36px] ml-[17px]"
        />
        <div className="w-[166px] sm:w-60 ml-2">
          <p class="text-black text-[8px] leading-3 sm:text-xs font-light font-['Fustat']">
            <span className="font-semibold">Segurança de dados: </span>
            garante a privacidade das informações de seus pacientes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyCard;
