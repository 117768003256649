import React from "react";
import "../../index.css";
import logoInstagram from "../../assets/icons/logo-instagram.png";
import logoLinkedin from "../../assets/icons/logo-linkedin.png";
import logoYoutube from "../../assets/icons/logo-youtube.png";

const Footer = () => {
  return (
    <div className="flex flex-row w-full h-[42px] sm:h-14 bg-[#F5F5F5] items-center px-8 justify-between sm:px-14 md:px-14 lg:px-[15%]">
      <div className="flex gap-1 sm:gap-2">
        <button>
          <img src={logoInstagram} alt="Instagram" className="w-4 sm:w-6" />
        </button>
        <button>
          <img src={logoLinkedin} alt="Linkedin" className="w-4 sm:w-6" />
        </button>
        <button>
          <img src={logoYoutube} alt="Youtube" className="w-4 sm:w-6" />
        </button>
      </div>
      <div className="flex flex-row gap-2 text-[8px] sm:text-[11px]">
        <p className="text-[#5c5c5c]/50 font-light font-['Ubuntu'] tracking-tight">
          2024
        </p>

        <p className="text-[#5c5c5c]/50 font-light font-['Ubuntu'] tracking-tight">
          © PsiControl
        </p>
        <a
          href="/privacy-policy" // A rota interna da política de privacidade
          className="text-[#5c5c5c]/50 font-light font-['Ubuntu'] underline tracking-tight"
        >
          Política de Privacidade
        </a>
        <a
          href="/terms-and-conditions" // A rota interna dos termos e condições
          className="text-[#5c5c5c]/50 font-light font-['Ubuntu'] underline tracking-tight"
        >
          Termos e condições
        </a>
      </div>
    </div>
  );
};

export default Footer;
