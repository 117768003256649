import React from "react";
import "../../index.css";

const TermsOfUse = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">
        Termos de Uso
      </h1>

      <p className="text-gray-600 text-lg mb-6">
        Ao utilizar o sistema Psicontrol, você concorda com os termos e
        condições descritos abaixo. Recomendamos a leitura completa deste
        documento antes de utilizar nossos serviços.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        1. Sobre o Psicontrol
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        O Psicontrol é um sistema web de gestão financeira e de atendimentos
        destinado a psicólogos, oferecido pela SDC Desenvolvimento de Sistemas,
        com sede em Pelotas – Rio Grande do Sul.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        2. Aceitação dos Termos
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        Ao acessar o Psicontrol, você declara que leu, compreendeu e concorda
        com estes Termos de Uso, bem como com a nossa Política de Privacidade.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        3. Cadastro e Acesso
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        O uso do sistema requer cadastro com informações verdadeiras e
        atualizadas. O usuário é responsável por manter a confidencialidade de
        suas credenciais.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        4. Uso Permitido
      </h2>
      <ul className="list-disc pl-6 text-gray-600 mb-4">
        <li>
          Utilizar o sistema apenas para fins profissionais relacionados à
          psicologia.
        </li>
        <li>
          É proibido revender, sublicenciar ou explorar comercialmente o sistema
          sem autorização.
        </li>
        <li>
          Não é permitido tentar acessar áreas restritas ou burlar medidas de
          segurança.
        </li>
        <li>
          O uso indevido poderá resultar em suspensão ou encerramento da conta.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        5. Planos e Pagamentos
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        O Psicontrol oferece planos gratuitos e pagos. Os valores e condições
        estão disponíveis no sistema e podem ser alterados mediante aviso
        prévio.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        6. Responsabilidades do Usuário
      </h2>
      <ul className="list-disc pl-6 text-gray-600 mb-4">
        <li>Garantir a veracidade dos dados informados.</li>
        <li>
          Manter sigilo sobre dados de pacientes e sobre sua própria conta.
        </li>
        <li>Respeitar a legislação e normas profissionais aplicáveis.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        7. Limitação de Responsabilidade
      </h2>
      <ul className="list-disc pl-6 text-gray-600 mb-4">
        <li>
          Não garantimos ausência total de falhas ou interrupções no sistema.
        </li>
        <li>
          A SDC não se responsabiliza por danos decorrentes do uso inadequado ou
          interrupções técnicas.
        </li>
        <li>O uso do sistema é feito sob total responsabilidade do usuário.</li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        8. Propriedade Intelectual
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        Todo o conteúdo do Psicontrol, incluindo marca, layout, códigos e
        textos, é propriedade da SDC Desenvolvimento de Sistemas. É proibido uso
        não autorizado.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        9. Suspensão e Cancelamento
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        Reservamo-nos o direito de suspender ou cancelar contas que violem estes
        termos ou comprometam o sistema.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        10. Alterações nos Termos
      </h2>
      <p className="text-gray-600 text-lg mb-4">
        Os Termos de Uso podem ser modificados a qualquer momento. As alterações
        serão comunicadas através do sistema. Recomendamos revisão periódica.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">11. Contato</h2>
      <p className="text-gray-600 text-lg mb-4">
        Para dúvidas sobre estes termos, entre em contato:
      </p>
      <p className="text-gray-600 text-lg mb-6">
        E-mail:{" "}
        <a
          href="mailto:contato@psicontrol.com.br"
          className="text-blue-600 hover:text-blue-800"
        >
          contato@psicontrol.com.br
        </a>
        <br />
        Empresa: SDC Desenvolvimento de Sistemas
        <br />
        Local: Pelotas – RS
      </p>
    </div>
  );
};

export default TermsOfUse;
