import React from "react";
import "../../index.css";
import LogoCubo from "../../assets/images/logo-cube.png";

const ContactBanner = () => {
  return (
    <section className="flex flex-col justify-center items-center h-[150px] sm:h-80 bg-[#0082ba] gap-3 sm:gap-7">
      <img src={LogoCubo} alt="Cubo" className="w-8 sm:w-14 " />
      <h1 className="text-center justify-start text-slate-200 text-xs sm:text-4xl font-medium font-raleway leading-[10px]">
        Entre em contato conosco
      </h1>
      <h2 class="w-80 sm:w-[750px] text-center justify-start text-neutral-100 text-[8.50px] sm:text-xl font-fustat font-light leading-[10px]">
        Tem dúvidas sobre como o PsiControl pode simplificar a gestão do seu
        consultório? Precisa de suporte ou quer compartilhar sua experiência com
        nosso sistema? Estamos prontos para ouvir você.
      </h2>
    </section>
  );
};

export default ContactBanner;
