import LogoCubeUp from "../assets/images/logo-cube-up.png";
import ButtonLoginGoogle from "../assets/images/button-login-google.png";
import MokckupLogin from "../assets/images/mockup-login.png";

function Login() {
  return (
    <section>
      <div className="relative flex flex-col h-[250px] items-center justify-center">
        <img src={LogoCubeUp} alt="Logo" className="w-60" />
      </div>

      <div className="relative flex flex-col items-center w-full h-[627px] rounded-tl-[40px] bg-[#DBE9F9]">
        <p className="w-80 text-3xl text-primaria font-raleway font-semibold text-center pt-10">
          Entre e experimente uma nova rotina
        </p>
        <p className="font-fustat font-light pt-2">
          Gestão leve e organizada com o Psicontrol
        </p>
        <div>
          <button onClick={() => (window.location.href = "/login")}>
            <img
              src={ButtonLoginGoogle}
              alt="Login com Google"
              className="w-80 bg-white rounded-lg mt-10 drop-shadow-lg"
            />
          </button>{" "}
        </div>
        <div>
          <img
            src={MokckupLogin}
            alt="Login com Google"
            className="w-80 rounded-lg mt-10"
          />
        </div>
        <p className="text-center text-sm w-80 text-texto2">
          Tudo isso sem complicações, sem burocracia e sem precisar instalar
          nada.
        </p>
      </div>
    </section>
  );
}

export default Login;
