import React from "react";
import "../../index.css";
import womanBackground from "../../assets/images/woman-background.png";
import WhyCard from "../common/WhyCard";

const HeroTwo = () => {
  return (
    <div
      className="sm:flex w-full bg-cover bg-right-top h-64 sm:h-[500px] xl:h-[600px] items-center"
      style={{ backgroundImage: `url(${womanBackground})` }}
    >
      <div className="absolute w-56 sm:w-80 right-6 sm:right-14 lg:right-[15%] mt-3 font-['Raleway'] text-[#003e60] text-xs sm:text-base xl:text-xl leading-[38px]">
        <div className="w-[169px] sm:w-72 h-[23px] xl:mt-10">
          <span className="font-medium">Porque usar o </span>
          <span className="font-semibold">PsiControl?</span>
        </div>
        <div className="w-[215px] sm:w-80 text-[#232323] text-[8.50px] sm:text-sm font-light font-['Fustat'] leading-3 mt-2 xl:mb-10">
          Descubra como o PsiControl facilita sua rotina e melhora a gestão do
          seu consultório com funcionalidades criadas para você.
        </div>
        <WhyCard />
      </div>
    </div>
  );
};

export default HeroTwo;
