import React from "react";
import "../../index.css";
import buttonWantPlan from "../../assets/images/button-want-plan.png";
import logoCubeBlue from "../../assets/images/logo-cube-blue.png";
import checkBlue from "../../assets/images/check-blue.png";
import PlanCardLine from "../../assets/images/plan-card-line.png";
import SoonButton from "../../assets/images/soon-button.png";

const Plans = () => {
  return (
    <div className="flex flex-col w-full h-[2050px] md:h-[2300px] lg:h-[1000px] bg-white items-center pt-6">
      <h3 className="text-center text-[#003e60] text-sm md:text-xl lg:text-2xl font-medium font-raleway">
        Os planos que vão mudar a rotina do seu consultório
      </h3>
      <h4 className="w-[300px] md:w-[500px] lg:w-[500px] text-[#232323] text-xs md:text-lg text-center font-light font-['Fustat'] leading-3 pt-2 pb-6">
        Escolha o plano ideal para o seu momento profissional e aproveite todos
        os benefícios do PsiControl.
      </h4>
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-8">
        {/* Plano Card 1 */}

        <div className="flex w-[300px] sm:w-[500px] lg:w-[300px] h-[700px] sm:h-[750px] lg:h-[700px] rounded-[15px] border border-[#0082ba] justify-center items-center mt-8">
          <div className="flex flex-col gap-2 w-[280px] sm:w-[480px] lg:w-[280px] h-[675px] sm:h-[725px] lg:h-[675px] bg-[#cbd6e2] rounded-[15px] justify-center items-center text-center">
            <img
              src={logoCubeBlue}
              alt="Logo Psicontrol"
              className="w-10 pt-4"
            />
            <p className="text-[#003E60] text-2xl font-semibold font-raleway underline leading-9">
              Plano Estudante
            </p>
            <p className="w-56 sm:w-96 lg:w-56 h-10 text-text-[#232323] text-xs sm:text-base lg:text-xs font-light font-fustat leading-tight">
              Funcionalidades básicas para a gestão financeira do seu
              consultório.
            </p>
            <p className="text-sky-900 text-[10px] md:text-lg lg:text-[10px] md:pt-2 lg:pt-0 font-light font-raleway underline leading-9 ">
              Veja os detalhes abaixo e compare
            </p>
            <div>
              <img
                src={PlanCardLine}
                alt="Linha horizontal"
                className="sm:hidden w-40 sm:w-72"
              ></img>{" "}
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Sincronização com o Google Agenda
                </p>
              </div>

              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Cadastro completo de pacientes
                </p>
              </div>

              <div className="flex items-center content-start">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Mensagem de cobrança automática com valores e datas das
                  consultas
                </p>
              </div>

              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Cadastro de despesas e receitas extras (saiba o valor real de
                  sua hora de consulta)
                </p>
              </div>
            </div>

            <p className="text-sky-900 text-3xl md:text-4xl lg:text-3xl font-semibold font-raleway pt-6">
              Grátis
            </p>
            <p className="text-sky-900 text-sm md:text-base lg:text-sm font-light font-raleway pb-8">
              *limitado ao cadastro de 5 pacientes
            </p>

            <button>
              <img
                src={buttonWantPlan}
                alt="Quero este plano"
                className="w-56"
              />
            </button>
          </div>
        </div>
        {/* Plano Card 2 */}
        <div className="flex w-[300px] sm:w-[500px] lg:w-[300px] h-[600px] sm:h-[650px] lg:h-[600px] rounded-[15px] border border-[#0082ba] justify-center items-center mt-8">
          <div className="flex flex-col gap-2 w-[280px] sm:w-[480px] lg:w-[280px] h-[575px] sm:h-[625px] lg:h-[575px] bg-[#cbd6e2] rounded-[15px] justify-start items-center text-center">
            <div className="flex bg-[#0082BA] w-full md:h-10 justify-center rounded-t-[15px] mb-4">
              <div className="flex text-neutral-100 text-sm md:text-base lg:text-sm font-normal font-fustat leading-relaxed pt-1 md:pt-3">
                A escolha do psicólogo profissional!
              </div>
            </div>
            <img
              src={logoCubeBlue}
              alt="Logo Psicontrol"
              className="w-10 pt-4"
            />
            <p className="text-[#003E60] text-2xl font-semibold font-raleway underline leading-9">
              Plano PRO
            </p>
            <p className="w-56 sm:w-96 lg:w-56 h-10 text-text-[#232323] text-xs sm:text-base lg:text-xs font-light font-fustat leading-tight">
              Funcionalidades para quem precisa de um sistema completo.
            </p>
            <p className="text-sky-900 text-[10px] md:text-lg lg:text-[10px] md:pt-2 font-light font-raleway underline leading-9">
              Veja os detalhes abaixo e compare
            </p>
            <div>
              <img
                src={PlanCardLine}
                alt="Linha horizontal"
                className="md:hidden w-40 lg:w-40"
              ></img>{" "}
            </div>

            <div className="flex flex-col gap-4">
              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Todas as funcionalidades do Plano Estudante
                </p>
              </div>

              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Cadastro de pacientes ilimitado
                </p>
              </div>
            </div>

            <p className="text-sky-900 text-3xl md:text-4xl lg:text-3xl font-semibold font-raleway pt-6">
              R$ 29,90
            </p>
            <p className="text-sky-900 text-sm font-light font-raleway pb-8">
              por mês, ou R$ 299,00 ao ano.
            </p>
            <button>
              <img
                src={buttonWantPlan}
                alt="Quero este plano"
                className="w-56"
              />
            </button>
          </div>
        </div>
        {/* Plano Card 3 */}
        <div className="flex w-[300px] sm:w-[500px] lg:w-[300px] h-[450px] md:h-[500px] lg:h-[450px] rounded-[15px] border border-[#0082ba] justify-center items-center mt-8">
          <div className="flex flex-col gap-2 w-[280px] sm:w-[480px] lg:w-[280px] h-[425px] md:h-[475px] lg:h-[420px] bg-[#cbd6e2] rounded-[15px] justify-center items-center text-center">
            <img
              src={logoCubeBlue}
              alt="Logo Psicontrol"
              className="w-10 pt-4"
            />
            <p className="text-[#003E60] text-2xl font-semibold font-raleway underline leading-9">
              Plano Premium
            </p>
            <p className="w-56 sm:w-96 lg:w-56 h-10 text-[#232323] text-xs sm:text-base lg:text-xs font-light font-fustat leading-tight">
              Tudo o que o Psicontrol pode oferecer para você e seu consultório.
            </p>
            <p className="text-sky-900 text-[10px] md:text-lg lg:text-[10px] md:pt-2 font-light font-raleway underline leading-9 ">
              Veja os detalhes abaixo e compare
            </p>
            <div>
              <img
                src={PlanCardLine}
                alt="Linha horizontal"
                className="md:hidden w-40"
              ></img>{" "}
            </div>

            <div className="relative flex flex-col gap-4">
              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Todas as funcionalidades o Plano PRO{" "}
                </p>
              </div>

              <div className="flex items-center">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Emissão automática de recibo através do Receita Saúde{" "}
                </p>
              </div>

              <div className="flex items-center content-start">
                <img src={checkBlue} alt="Check" className="w-8"></img>
                <p className="text-xs md:text-lg lg:text-xs font-raleway text-[#232323] w-56 sm:w-96 lg:w-56 text-start pl-2">
                  Comprovação automática dos pagamentos recebidos
                </p>
              </div>
              <button>
                <img
                  src={SoonButton}
                  alt="Em breve"
                  className="absolute w-36 -right-16 -top-[280px]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
