import Navbar from "../components/layout/Navbar";

import Footer from "../components/layout/footer";
import AboutHero from "../components/sections/AboutHero";

function About() {
  return (
    <div>
      <Navbar />
      <AboutHero />
      <Footer />
    </div>
  );
}

export default About;
