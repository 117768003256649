import React from "react";
import "../../index.css";
import WhoMosaic from "../../assets/images/who-mosaic.png";
import HistoryMosaic from "../../assets/images/history-mosaic.png";
import CommitmentMosaic from "../../assets/images/commitment-mosaic.png";
import AboutCard from "../common/AboutCard";
import AboutBgMd from "../../assets/images/about-bg-md.png";

const AboutHero = () => {
  return (
    <div className="relative h-[850px] sm:h-[1400px] md:h-[900px] bg-gradient-to-b from-[#DBE9EF] via-[#6DB5D4] to-[#0B87BD] md:bg-white md:from-transparent md:via-transparent md:to-transparent">
      <div>
        <img
          src={AboutBgMd}
          alt="Background"
          className="hidden md:flex md:absolute md:top-72 md:left-0 md:w-full md:h-96"
        />
      </div>
      <div className="absolute text-sky-900 text-sm md:text-lg font-semibold font-raleway pl-10 pt-8 md:pt-14 md:pl-40">
        Sobre o Psicontrol
      </div>

      <div className="flex flex-col md:flex-row gap-20 md:gap-10 xl:gap-20 justify-center items-center pt-24 sm:pt-36 md:pt-56 sm:gap-40">
        <AboutCard
          image={WhoMosaic}
          title="Quem somos"
          paragraphs={[
            "No PsiControl, entendemos a importância de simplificar a gestão financeira para profissionais de psicologia.",
            "Nossa missão é oferecer uma solução prática, eficiente e agradável, para que você possa focar no que realmente importa: cuidar das pessoas.",
          ]}
        />
        <AboutCard
          image={HistoryMosaic}
          title="Nossa História"
          paragraphs={[
            "A ideia do PsiControl nasceu da observação de uma necessidade comum entre psicólogos: uma ferramenta que unisse organização e funcionalidade em um só lugar. ",
            "Desde então, trabalhamos para criar um sistema acessível, que se adapte ao seu dia a dia.",
          ]}
        />
        <AboutCard
          image={CommitmentMosaic}
          title="Nosso Compromisso"
          paragraphs={[
            "Estamos sempre ouvindo nossos clientes, evoluindo o sistema e trazendo funcionalidades que realmente fazem a diferença. ",
            "Nosso objetivo é ser mais do que um sistema: queremos ser parceiros no seu sucesso.",
          ]}
        />
      </div>
      <p className="hidden md:text-black md:bottom-0">
        Trabalhamos para simplificar sua rotina
      </p>
      <p className="hidden md:text-black md:bottom-0">
        experimente grátis por 30 dias e conheça os benefícios de usar o
        Psicontrol.
      </p>
    </div>
  );
};

export default AboutHero;
