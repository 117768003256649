import Navbar from "../components/layout/Navbar";

import Footer from "../components/layout/footer";
import PlansSectionOne from "../components/sections/PlansSectionOne";
import BottomBanner from "../components/sections/BottomBanner";

function App() {
  return (
    <div>
      <Navbar />
      <PlansSectionOne />
      <BottomBanner />
      <Footer />
    </div>
  );
}

export default App;
