import Navbar from "../components/layout/Navbar";

import Hero from "../components/sections/Hero";

import Footer from "../components/layout/footer";

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Footer />
    </div>
  );
}

export default App;
