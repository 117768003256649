import ContactBanner from "../components/sections/ContactBanner";
import Navbar from "../components/layout/Navbar";

import ContactForm from "../components/sections/ContactForm";

import Footer from "../components/layout/footer";

function App() {
  return (
    <div>
      <Navbar />
      <ContactBanner />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default App;
