import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import arrowLeft from "../../assets/images/arrow-left.png";
import arrowRight from "../../assets/images/arrow-right.png";

const testimonials = [
  {
    id: 1,
    text: "Com o PsiControl, economizo horas toda semana. A gestão do meu consultório nunca foi tão fácil!",
    author: "- Psicólogo João Pereira",
  },
  {
    id: 2,
    text: "O PsiControl melhorou muito minha organização. Recomendo para todos os profissionais da área!",
    author: "- Psicóloga Maria Santos",
  },
  {
    id: 3,
    text: "A praticidade e eficiência do PsiControl fazem toda a diferença no meu dia a dia!",
    author: "- Psicólogo Lucas Almeida",
  },
];

export default function TestimonialsCarousel() {
  return (
    <div className="relative max-w-4xl mx-auto px-4 py-8">
      <h2 className="text-center text-[#003e60] text-sm sm:text-lg font-medium font-['Raleway']">
        Depoimentos de clientes PsiControl
      </h2>

      {/* Swiper */}
      <Swiper
        spaceBetween={20}
        slidesPerView={1}
        navigation={{ nextEl: ".next-btn", prevEl: ".prev-btn" }}
        modules={[Navigation]}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
        }}
        className="w-full"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide
            key={testimonial.id}
            className="relative flex flex-row p-4"
          >
            <div className="border-[#0082BA] border-2 rounded-2xl mx-2 shadow-md p-4">
              <p className="text-black text-[12px] sm:text-lg  mx-4">
                "{testimonial.text}"
              </p>
              <p className="mt-4 ml-4 text-3 sm:text-base font-semibold text-black">
                {testimonial.author}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Botões de navegação - Agora estão FORA do Swiper */}
      <button className="prev-btn absolute top-1/2 left-0 transform -translate-y-1/2 z-10">
        <img src={arrowLeft} alt="Seta para esquerda" className="w-10" />
      </button>
      <button className="next-btn absolute top-1/2 right-0 transform -translate-y-1/2 z-10">
        <img src={arrowRight} alt="Seta para direita" className="w-10" />
      </button>
    </div>
  );
}
