// RegisterDropdown.jsx
import { useState } from "react";
import { Menu } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export default function RegisterDropDown({ value, onChange }) {
  const options = [
    "Profissional Individual",
    "Administrador(a) de Clínica",
    "Estudante de Psicologia",
    "Recém formado(a)",
  ];

  return (
    <div className="flex flex-col lg:w-96">
      <label htmlFor="dropdown" className="text-sm font-medium text-black mb-2">
        Sobre você*
      </label>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            id="dropdown"
            className={`inline-flex w-80 lg:w-96 justify-between rounded-md bg-white px-3 py-2 text-sm font-semibold ${
              value ? "text-gray-900" : "text-gray-500"
            } ring-1 shadow-xs ring-gray-300 ring-inset hover:bg-gray-50`}
          >
            {value || "Selecionar"}
            <ChevronDownIcon
              aria-hidden="true"
              className="ml-2 size-5 text-gray-500"
            />
          </Menu.Button>
        </div>

        <Menu.Items className="absolute right-0 z-10 mt-2 w-80 origin-top-right rounded-md bg-white ring-1 shadow-lg ring-black/5 focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <Menu.Item key={option}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => onChange(option)}
                    className={`w-full text-left px-4 py-2 text-sm ${
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700"
                    }`}
                  >
                    {option}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
}
