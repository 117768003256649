import React from "react";
import "../../index.css";
import bottomBannerBackground from "../../assets/images/bottom-banner-background.png";
import botaoTeste from "../../assets/images/test-button.png";
import bottomBannerBackgroundLg from "../../assets/images/bottom-banner-background-lg.png";

const BottomBanner = () => {
  return (
    <>
      {/* Imagem para mobile */}
      <section
        className="sm:hidden grid w-full bg-cover bg-right h-[268px] place-content-end justify-center"
        style={{ backgroundImage: `url(${bottomBannerBackground})` }}
      >
        <div className="relative flex flex-col w-[200px] sm:w-[300px] text-center pb-[70px] -mr-32 items-center sm:ml-20">
          <p className="relative text-[#e6eff5] text-xs sm:text-lg text-center font-semibold font-['Raleway'] leading-[13px]">
            Começe hoje mesmo a transformar seu consultório!
          </p>
          <p className="relative text-center text-neutral-100 text-[8.50px] sm:text-xs font-light font-['Fustat'] leading-[10px] pt-3">
            Cadastre-se agora e experimente grátis por 30 dias.
          </p>
          <button onClick={() => (window.location.href = "/register")}>
            <img
              src={botaoTeste}
              alt="Teste nosso sistema"
              className="relative w-44 pt-8 pl-4"
            />
          </button>
        </div>
      </section>

      {/* Imagem para telas maiores */}
      <section
        className="hidden sm:grid w-full bg-cover bg-center h-[268px] lg:h-80 place-content-end justify-center content-center"
        style={{ backgroundImage: `url(${bottomBannerBackgroundLg})` }}
      >
        <div className="relative flex flex-col w-[200px] sm:w-[300px] md:w-[320px] text-center -mr-32 items-center sm:ml-[70%] md:ml-[80%]">
          <p className="relative text-[#e6eff5] text-xs sm:text-lg md:text-xl text-center font-semibold font-['Raleway'] leading-[13px]">
            Começe hoje mesmo a transformar seu consultório!
          </p>
          <p className="relative text-center text-neutral-100 text-[8.50px] sm:text-xs md:text-sm font-light font-['Fustat'] leading-[10px] pt-3">
            Cadastre-se agora e experimente grátis por 7 dias.
          </p>
          <button onClick={() => (window.location.href = "/register")}>
            {" "}
            <img
              src={botaoTeste}
              alt="Teste nosso sistema"
              className="relative w-44 pt-8 pl-4"
            />
          </button>
        </div>
      </section>
    </>
  );
};

export default BottomBanner;
