import React from "react";
import "../../index.css";
import botaoTeste from "../../assets/images/test-button.png";
import Computer from "../../assets/images/computer.png";

const HeroOne = () => {
  return (
    <section className="flex sm:justify-between w-full bg-cover bg-right h-60 sm:h-[320px] lg:h-[400px] bg-hero-background-mobile sm:bg-hero-background-desktop px-8 sm:px-14 lg:px-[15%]">
      <div className="self-center">
        <h1 className="w-80 sm:w-72 md:w-96 font-raleway lg:text-xl text-base text-primariaEscura1">
          <span className="font-semibold">Psicontrol:</span>
          <span className="font-medium pl-1">
            a solução completa para a gestão financeira de seu consultório.
          </span>
        </h1>
        <h2 className="w-80 sm:w-72 md:w-96 lg:w-80 font-fustat text-xs lg:text-md font-light leading-[17px] text-texto2 pt-4">
          Organize suas consultas, simplifique cobranças e acompanhe pagamentos
          de forma prática e eficiente.
        </h2>
        <button onClick={() => (window.location.href = "/register")}>
          <img
            src={botaoTeste}
            alt="Botão Teste"
            className="w-44 lg:w-56 pt-6"
          />
        </button>
      </div>
      <div className="hidden sm:grid self-end">
        <img
          src={Computer}
          alt="Computer"
          className="sm:w-[280px] lg:w-[400px] md:h-auto mb-3"
        />
      </div>
    </section>
  );
};

export default HeroOne;
