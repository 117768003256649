import React from "react";
import "../../index.css";
import logoPsicontrol from "../../assets/images/logo-psicontrol.png";

const Navbar = () => {
  return (
    <nav className="w-[100%] h-14 sm:h-20 flex flex-row items-end bg-[#F5F5F5] px-8 pb-2 sm:px-14 md:px-14 lg:px-[15%] sm:items-center sm:pb-0">
      <div className="navbar-logo">
        <a href="/">
          <img src={logoPsicontrol} alt="Logo" className="w-32 sm:w-48 pb-1 " />
        </a>
      </div>
      <ul className="w-[100%] flex flex-row justify-end font-['Fustat'] text-primaria text-xs sm:text-lg gap-4 sm:gap-6 md:gap-8">
        <li>
          <a href="/login">Login</a>
        </li>
        <li>
          <a href="plans">Planos</a>
        </li>
        <li>
          <a href="About">Sobre</a>
        </li>
        <li>
          <a href="contact">Contato</a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
