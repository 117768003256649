import { Routes, Route } from "react-router-dom"; // Importando Routes
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Plans from "../pages/Plans";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";
import Register from "../pages/Register";
import Login from "../pages/Login";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/plans" element={<Plans />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />{" "}
      {/* Redirecionamento para a página externa */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/terms-and-conditions"
        element={<TermsAndConditions />}
      />{" "}
    </Routes>
  );
};

export default AppRoutes;
