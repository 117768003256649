import React from "react";
import "../../index.css";

const PrivacyPolicy = () => {
  return (
    <div class="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 class="text-3xl font-semibold text-gray-800 mb-6">
        Política de Privacidade
      </h1>

      <p class="text-gray-600 text-lg mb-6">
        A sua privacidade é importante para nós. Esta Política de Privacidade
        descreve como o Psicontrol coleta, utiliza, armazena e protege as
        informações dos usuários. Ao acessar e usar o nosso sistema, você
        concorda com os termos descritos nesta política.
      </p>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        1. Informações que Coletamos
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        Coletamos as seguintes informações para proporcionar a melhor
        experiência para nossos usuários:
      </p>

      <h3 class="text-xl font-semibold text-gray-800 mb-2">
        1.1 Dados Pessoais dos Psicólogos
      </h3>
      <ul class="list-disc pl-6 text-gray-600 mb-4">
        <li>Nome completo</li>
        <li>Endereço de e-mail</li>
        <li>Número de telefone</li>
        <li>CRM ou registro profissional</li>
        <li>Endereço de consulta (se fornecido)</li>
      </ul>

      <h3 class="text-xl font-semibold text-gray-800 mb-2">
        1.2 Dados dos Pacientes
      </h3>
      <ul class="list-disc pl-6 text-gray-600 mb-4">
        <li>Nome completo</li>
        <li>Data de nascimento</li>
        <li>Endereço de e-mail e telefone</li>
        <li>
          Informações de saúde (como histórico de atendimentos e observações
          clínicas)
        </li>
        <li>
          Dados financeiros (como informações de cobrança, vencimentos e status
          de pagamentos)
        </li>
      </ul>

      <h3 class="text-xl font-semibold text-gray-800 mb-2">
        1.3 Dados Financeiros
      </h3>
      <ul class="list-disc pl-6 text-gray-600 mb-6">
        <li>Histórico de transações</li>
        <li>
          Detalhes de pagamento (ex: dados de cartão de crédito ou informações
          bancárias, se aplicável)
        </li>
      </ul>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        2. Como Usamos suas Informações
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        As informações coletadas são utilizadas para os seguintes fins:
      </p>

      <ul class="list-decimal pl-6 text-gray-600 mb-4">
        <li>
          Cadastro e gerenciamento de usuários: Para permitir o acesso ao
          sistema e fornecer os serviços oferecidos.
        </li>
        <li>
          Controle de atendimentos e pacientes: Para os psicólogos gerenciarem
          seus pacientes e informações de saúde de forma eficiente.
        </li>
        <li>
          Gestão financeira e cobranças: Para realizar o controle financeiro,
          geração de faturas e o acompanhamento de cobranças.
        </li>
        <li>
          Comunicação: Para enviar notificações importantes sobre o sistema,
          como atualizações, mudanças nos termos de uso e suporte.
        </li>
        <li>
          Análise de uso do sistema: Para melhorar continuamente a experiência
          do usuário e otimizar as funcionalidades.
        </li>
      </ul>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        3. Como Protegemos suas Informações
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        A segurança dos seus dados é nossa prioridade. Implementamos medidas de
        segurança física, técnica e administrativa para proteger suas
        informações pessoais contra acesso, alteração, divulgação ou destruição
        não autorizados.
      </p>

      <ul class="list-decimal pl-6 text-gray-600 mb-6">
        <li>
          Criptografia: Todos os dados transmitidos através do nosso sistema são
          criptografados.
        </li>
        <li>
          Acesso restrito: Apenas os usuários autorizados, como os psicólogos,
          têm acesso aos dados de seus pacientes.
        </li>
        <li>
          Armazenamento seguro: Utilizamos serviços de hospedagem confiáveis e
          protocolos de segurança para garantir que as informações estejam
          protegidas.
        </li>
      </ul>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        4. Compartilhamento de Informações
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        Não compartilhamos suas informações pessoais com terceiros, exceto nas
        seguintes situações:
      </p>

      <ul class="list-decimal pl-6 text-gray-600 mb-4">
        <li>
          Cumprimento de obrigações legais: Podemos divulgar suas informações
          quando exigido por lei, como em casos de investigações legais ou para
          cumprir com obrigações regulatórias.
        </li>
        <li>
          Serviços terceirizados: Podemos compartilhar suas informações com
          prestadores de serviços terceiros, como processadores de pagamento,
          que nos ajudam a fornecer os serviços do Psicontrol. Esses terceiros
          são obrigados a proteger seus dados de acordo com nossas políticas de
          privacidade.
        </li>
      </ul>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        5. Seus Direitos
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        De acordo com a Lei Geral de Proteção de Dados Pessoais (LGPD), você tem
        o direito de:
      </p>

      <ul class="list-decimal pl-6 text-gray-600 mb-4">
        <li>
          Acessar: Solicitar uma cópia das suas informações pessoais que temos
          armazenadas.
        </li>
        <li>
          Corrigir: Solicitar a correção de informações imprecisas ou
          incompletas.
        </li>
        <li>
          Excluir: Solicitar a exclusão de seus dados pessoais, quando não forem
          mais necessários para os fins para os quais foram coletados.
        </li>
        <li>
          Revogar o consentimento: A qualquer momento, você pode revogar seu
          consentimento para o processamento dos seus dados pessoais.
        </li>
      </ul>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        6. Cookies e Tecnologias de Rastreamento
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        Utilizamos cookies e outras tecnologias de rastreamento para coletar
        informações sobre o uso do nosso sistema e melhorar a sua experiência.
        Você pode optar por desativar os cookies no seu navegador, mas isso pode
        afetar a funcionalidade do sistema.
      </p>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">
        7. Alterações nesta Política de Privacidade
      </h2>

      <p class="text-gray-600 text-lg mb-4">
        Reservamo-nos o direito de atualizar esta Política de Privacidade a
        qualquer momento. Quando fizermos alterações, publicaremos a versão
        atualizada no nosso site e alteraremos a data da última atualização.
        Recomendamos que você revise periodicamente esta página para estar
        ciente de quaisquer mudanças.
      </p>

      <h2 class="text-2xl font-semibold text-gray-800 mb-4">8. Contato</h2>

      <p class="text-gray-600 text-lg mb-4">
        Se você tiver dúvidas ou preocupações sobre esta Política de
        Privacidade, entre em contato conosco:
      </p>

      <p class="text-gray-600 text-lg mb-6">
        E-mail:{" "}
        <a
          href="mailto:contato@psicontrol.com.br"
          class="text-blue-600 hover:text-blue-800"
        >
          contato@psicontrol.com.br
        </a>
        <br />
        Endereço: [Endereço da empresa, se aplicável]
      </p>
    </div>
  );
};

export default PrivacyPolicy;
