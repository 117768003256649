import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/footer";
import TermsOfUse from "../components/policy/TermsAndConditions";

function App() {
  return (
    <div>
      <Navbar />
      <TermsOfUse />
      <Footer />
    </div>
  );
}

export default App;
