export default function AboutCard({ image, title, paragraphs }) {
  return (
    <div className="relative flex w-60 sm:w-96 md:w-60 h-44 sm:h-72 md:h-44 lg:h-60 bg-gray-100 rounded-[10px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] border-[0.50px] border-sky-600 justify-center items-start pt-8">
      {image && (
        <img
          src={image}
          alt={title}
          className="absolute w-24 sm:w-40 md:w-24 h-auto -right-1 sm:-right-2 md:-right-1 -top-12 sm:-top-20 md:-top-12"
        />
      )}
      <div className="flex flex-col w-52 sm:w-[350px] md:w-52 gap-1 sm:pt-4 md:pt-0">
        <div className="text-base sm:text-2xl md:text-base sm:pb-4 md:pb-0">
          {title}
        </div>
        <div className="flex flex-col text-black text-[9px] sm:text-base md:text-[9px] lg:text-xs md:leading-[1.4] font-light font-fustat gap-2">
          {paragraphs.map((text, idx) => (
            <p key={idx}>{text}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
