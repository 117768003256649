import React, { useState } from "react";
import LogoCubeUp from "../assets/images/logo-cube-up.png";
import RegisterDropdown from "../components/common/RegisterDropdown";
import ButtonCreateAccount from "../assets/images/button-create-account-google.png";
import InputMask from "react-input-mask";
import RegisterBackgroundElipse from "../assets/images/register-background-elipse.png";
import RegisterMockup from "../assets/images/register-mockup.png";

function Register() {
  const [profissao, setProfissao] = useState("");
  const [crp, setCrp] = useState("");
  const [naoPossuoCRP, setNaoPossuoCRP] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    // Validação dos termos de uso
    if (!form.termos.checked) {
      alert("Você precisa aceitar os termos.");
      return;
    }

    if (!profissao) {
      alert("Selecione uma opção em 'Sobre você'");
      return;
    }

    const nome = form["nome-completo"].value;
    const telefone = form["telefone"].value;

    console.log("Formulário OK:", {
      nome,
      telefone,
      profissao,
      crp,
      naoPossuoCRP,
    });
  };

  const handleCrpChange = (e) => {
    setCrp(e.target.value);
    // Se o usuário digitar no input, desmarca a checkbox
    if (naoPossuoCRP) {
      setNaoPossuoCRP(false);
    }
  };

  const handleNaoPossuoCRPChange = (e) => {
    setNaoPossuoCRP(e.target.checked);
    // Se marcar a checkbox, limpa o campo
    if (e.target.checked) {
      setCrp("");
    }
  };

  return (
    <div className="lg:grid lg:grid-cols-5">
      <section className="flex flex-col lg:col-span-2 items-center justify-center lg:justify-start lg:pt-10 h-[950px]">
        <div>
          <img src={LogoCubeUp} alt="Logo" className="w-44 pb-10" />
        </div>
        <p className="w-60 lg:w-96 font-ubuntu text-[#003E60] text-center text-lg lg:text-base font-bold lg:font-medium pb-3 lg:pb-0">
          Teste o PsicontrolPRO por 30 dias grátis
        </p>
        <p className="flex text-center font-ubuntu text-[#003E60] pb-2">
          Não precisa de cartão de crédito, é simples!
        </p>
        <div className="h-px bg-gray-300 mt-2 mb-2 w-80 lg:hidden"></div>

        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center gap-4 mt-8"
        >
          <div className="flex flex-col">
            <label
              htmlFor="nome-completo"
              className="text-sm font-medium text-black mb-1"
            >
              Nome completo*
            </label>
            <input
              type="text"
              name="nome-completo"
              id="nome-completo"
              placeholder="Digite seu nome completo"
              className="w-80 lg:w-96 h-12 rounded-md border-[0.5px] border-texto-2 pl-3"
              required
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="telefone"
              className="text-sm font-medium text-black mb-1"
            >
              Celular/Whatsapp*
            </label>
            <InputMask
              mask="(99) 99999-9999"
              placeholder="(00) 00000-0000"
              type="tel"
              name="telefone"
              id="telefone"
              className="w-80 lg:w-96 h-12 rounded-md border-[0.5px] border-texto-2 pl-3"
              required
            />
          </div>

          <RegisterDropdown value={profissao} onChange={setProfissao} />

          <div className="flex flex-col lg:flex-row lg:self-start">
            <div className="flex flex-col">
              <label
                htmlFor="crp"
                className="text-sm font-medium text-black mb-1"
              >
                CRP
              </label>
              <input
                type="text"
                id="crp"
                name="crp"
                placeholder="Digite seu CRP"
                value={crp}
                onChange={handleCrpChange}
                disabled={naoPossuoCRP}
                className={`w-80 lg:w-52 h-12 rounded-md border-[0.5px] border-texto-2 pl-3 ${
                  naoPossuoCRP
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : ""
                }`}
              />
            </div>
            <label className="mt-1 lg:mt-5 flex items-center gap-2 text-sm text-gray-700">
              <input
                type="checkbox"
                id="naoPossuoCRP"
                name="naoPossuoCRP"
                checked={naoPossuoCRP}
                onChange={handleNaoPossuoCRPChange}
                className=" ml-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              Informar mais tarde
            </label>
          </div>

          <div className="flex w-72 space-x-2 text-sm font-fustat text-center pr-4">
            <input
              type="checkbox"
              id="termos"
              name="termos"
              className=" h-6 w-6 ml-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              required
            />
            <label htmlFor="termos" className="text-gray-600">
              Li e concordo com o{" "}
              <a
                href="/termo-de-uso"
                className="text-blue-600 underline hover:text-blue-800"
              >
                Termo de Uso
              </a>{" "}
              e{" "}
              <a
                href="/politica-de-privacidade"
                className="text-blue-600 underline hover:text-blue-800"
              >
                Política de Privacidade
              </a>
            </label>
          </div>
          <button type="submit" className="pt-6">
            <img src={ButtonCreateAccount} alt="Ícone" className="w-80" />
          </button>
        </form>

        <p className="text-sm text-gray-600 text-centerv pt-4">
          Já tenho cadastro:{" "}
          <a
            href="/login"
            className="text-blue-600 underline hover:text-blue-800"
          >
            Fazer Login
          </a>
        </p>
      </section>
      <section className="hidden lg:flex lg:relative lg:col-span-3">
        <img
          src={RegisterBackgroundElipse}
          alt="Elipse"
          className="w-full h-full "
        />
        <div className="absolute inset-0 flex flex-col text-center items-end">
          <div className="flex flex-col items-center mr-48">
            <p className="w-96 mt-24 text-center font-raleway font-semibold text-2xl text-primariaEscura1">
              Tudo que você precisa para gerenciar o seu consultório em um só
              lugar
            </p>
            <p className="text-texto2 font-light text-base mt-4">
              Controle de pacientes - simples, rápido e seguro
            </p>
          </div>
          <img
            src={RegisterMockup}
            alt="Mockup"
            className="w-[600px] mr-32 mt-8"
          />
          <p className="w-96 text-texto2 font-light font-base mr-48">
            Tudo isso sem complicações, sem burocracia e sem precisar instalar
            nada.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Register;
